<template>
  <div>
    <v-card>
      <!-- <pre>{{dataListContact2}}</pre> -->
      <v-card-text>
        <v-row>
          <v-col cols="12">
            <h2>Survey Analytics</h2>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-btn
              color="secondary"
              outlined
              class="mr-2"
              :to="{ name: 'e-form' }"
            >
              Back
            </v-btn>
            <v-btn
              color="primary"
              class="mr-2"
              @click="view = 'charts'"
            >
              Charts
              <v-icon class="ml-2">
                {{ icons.mdiChartBox }}
              </v-icon>
            </v-btn>
            <v-btn
              color="primary"
              @click="view = 'table'"
            >
              Table
              <v-icon class="ml-2">
                {{ icons.mdiTable }}
              </v-icon>
            </v-btn>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <div
              v-show="view == 'table'"
              id="tableContainer"
            ></div>
            <div
              v-show="view == 'charts'"
              id="summaryContainer"
            ></div>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import * as SurveyVue from 'survey-vue'
import * as SurveyAnalytics from 'survey-analytics'
import { Tabulator } from 'survey-analytics/survey.analytics.tabulator'
import Vue from 'vue'
import LoadScript from 'vue-plugin-load-script'
import { mdiTable, mdiChartBox } from '@mdi/js'
import PouchDB from 'pouchdb-browser'
import eFormMixin from '@/mixins/eFormMixin'
import 'jspdf-autotable'
import 'survey-analytics/survey.analytics.tabulator.css'
import 'tabulator-tables/dist/css/tabulator.min.css'
import 'survey-analytics/survey.analytics.css'
import pouchDbMixin from '../../mixins/pouchDbMixin'
import contactMixin from '../../mixins/contactMixin'

PouchDB.adapter('worker', require('worker-pouch'))

Vue.use(LoadScript)

/* eslint-disable vue/no-unused-components */
export default {
  name: 'EFormAnalytics',

  components: {
    SurveyVue,
  },

  mixins: [eFormMixin, pouchDbMixin, contactMixin],

  setup() {
    return {
      icons: {
        mdiTable,
        mdiChartBox,
      },
    }
  },

  data() {
    const json = {
      title: 'Default',
      showProgressBar: 'top',
      pages: [
        {
          questions: [
            {
              type: 'text',
              name: 'default',
              title: 'This is default survey',
            },
          ],
        },
      ],
    }
    const model = new SurveyVue.Model(json)

    return {
      contactListTable: [],
      listContactValue: [],
      labelData: [],
      contactListSetting: null,
      view: 'charts',
      eForm: [],
      survey: model,
      data: [],
    }
  },

  computed: {
    // allSettings() {
    //   return this.$store.getters['global/getSettings']
    // },
    user() {
      return this.$store.getters['auth/getUser']
    },

    // dataListContact2() {
    //   return this.listContactValue
    // },
  },

  async mounted() {
    // await this.loadSetting(true)
    // await this.loadTable()
    // this.$eventBus.$on(process.env.VUE_APP_EVENT_UPDATE_SETTINGS, async payload => {
    //   if (payload.id === 'contact_list') {
    //     await this.$store.dispatch('global/setSettingsById', payload)
    //     this.replicateFromPouchDbGlobal(process.env.VUE_APP_DB_SETTING_NAME, this.user, async data => {
    //       await this.loadSetting(true)
    //       await this.loadTable()
    //     })

    //     // await this.loadSetting()
    //   }
    // })

    await this.$loadScript('https://cdnjs.cloudflare.com/ajax/libs/jspdf/1.5.3/jspdf.min.js')
    await this.$loadScript(
      'https://cdnjs.cloudflare.com/ajax/libs/jspdf-autotable/3.0.10/jspdf.plugin.autotable.min.js',
    )
    await this.$loadScript('https://oss.sheetjs.com/sheetjs/xlsx.full.min.js')

    const { id } = this.$route.params

    // let contactData = this.dataListContact2.find(el => {
    //   return el.id === id && el.eform
    // })
    // //console.log(contactData)

    if (id) {
      await this.getEFormById({ id })
      //console.log(this.eForm)
      await this.getEFormAnalytics({ eform_survey_id: id })

      this.eForm.survey.showProgressBar = 'top'
      this.survey = new SurveyVue.Model(this.eForm.survey)

      // this.eFormSurveyId = id

      const surveyAnalyticsDataTables = new Tabulator(this.survey, this.data)
      surveyAnalyticsDataTables.render(document.getElementById('tableContainer'))

      const surveyAnalyticsCharts = new SurveyAnalytics.VisualizationPanel(this.survey.getAllQuestions(), this.data)
      surveyAnalyticsCharts.render(document.getElementById('summaryContainer'))
    }
  },
  methods: {
    async loadTable() {
      const obj = (await this.loadContactsAll()).contacts.filter(el => el._id !== el.phone_number)
      const listContactObj = this.listContactValue.map(el => {
        //console.log(el.id)
        el.active_contact = obj.filter(elm => elm._id.substring(0, 8) == el.id).length

        return el
      })
      this.listContactValue = [...listContactObj]
      this.totalContact = obj.totalContact
    },
    async loadSetting(isExist = false) {
      if (!isExist) {
        await this.loadSettings()
      }
      const res = await this.loadSettingContactsList(this.allSettings)
      this.labelData = res.labelData
      this.contactListSetting = res.contactListSetting
      this.listContactValue = res.listContactValue
      this.deleteUnusedContact(this.contactListTable, this.listContactValue)
    },
  },
}
</script>


<style>
.sa-commercial {
  display: none !important;
}
</style>

